import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { resolveIntl } from './resolveIntl';
import translation_da from './translation/da.json';
import translation_en from './translation/en.json';

const i18n = i18next.createInstance();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translation_en,
      },
      da: {
        translation: translation_da,
      },
    },
    lng: resolveIntl?.()?.locale || 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
export const t = i18n.t;
export const changeLanguage = i18n.changeLanguage;
