import { Colors, EmblaTheme, colorObj, lightTheme } from '@joinembla/theme';
import { MantineColorsTuple, mergeMantineTheme } from '@mantine/core';

type NestedStringMap = { [key: string]: string | NestedStringMap };

export const getNestedStringMap = (object: NestedStringMap, cursor = '', entries?: [string, string][]) => {
  const _entries = entries ?? [];

  Object.keys(object).forEach((key: string) => {
    let value = object[key as keyof typeof object];

    if (typeof value === 'string') {
      if (value.startsWith('data:')) value = `url(${value})`;

      _entries.push([`${cursor}-${key}`, value]);
    } else if (typeof value === 'object') {
      getNestedStringMap(value, `${cursor}-${key}`, _entries);
    }
  });

  return Object.fromEntries(_entries);
};

Object.keys(colorObj).forEach((colorName) => {
  const padAmount = 10 - colorObj[colorName as Colors].length;
  for (let i = 0; i < padAmount; i++) colorObj[colorName as Colors].push('#EE46BC');
});

export const newColors = colorObj as unknown as Record<Colors, MantineColorsTuple>;

export const loadFont = async (name: string, url: string, weight: number, style = 'normal') => {
  if (
    Array.from(document.fonts.values()).some(
      (ff) => ff.family === name && ff.weight === String(weight) && ff.style === style,
    )
  )
    return; // early return in case we already loaded this font

  const font = new FontFace(name, `url(${url})`, {
    weight: String(weight),
    style,
  });

  await font.load();

  document.fonts.add(font);
};

const loadThemeFont = async (config: EmblaTheme['assets']['primaryFont'], fontAssetMap: Record<string, string>) => {
  await Promise.all([
    loadFont(config.name, fontAssetMap[config.weights.light.normal], 300),
    loadFont(config.name, fontAssetMap[config.weights.light.italic], 300, 'italic'),
    loadFont(config.name, fontAssetMap[config.weights.regular.normal], 500),
    loadFont(config.name, fontAssetMap[config.weights.regular.italic], 500, 'italic'),
    loadFont(config.name, fontAssetMap[config.weights.semiBold.normal], 600),
    loadFont(config.name, fontAssetMap[config.weights.semiBold.italic], 600, 'italic'),
    loadFont(config.name, fontAssetMap[config.weights.bold.normal], 700),
    loadFont(config.name, fontAssetMap[config.weights.bold.italic], 700, 'italic'),
  ]);
};

export const loadFontAssets = async (theme: EmblaTheme) => {
  const fontAssetMap = theme.assets.fonts.reduce<Record<string, string>>(
    (prev, cur) => ({ ...prev, [cur.name]: cur.source }),
    {},
  );

  await loadThemeFont(theme.assets.primaryFont, fontAssetMap);
  await loadThemeFont(theme.assets.secondaryFont, fontAssetMap);
};

export const getEmblaTheme = (): EmblaTheme => {
  return lightTheme;
};

export const getMantineThemeAdditions = (themeOverride?: EmblaTheme): Parameters<typeof mergeMantineTheme>[1] => {
  const emblaTheme = themeOverride ?? getEmblaTheme();
  const baseTheme: Parameters<typeof mergeMantineTheme>[1] = {
    ...emblaTheme,
    fontFamily: emblaTheme.assets.primaryFont.name,
    headings: {
      fontFamily: emblaTheme.assets.primaryFont.name,
    },
    other: {
      theme: emblaTheme,
    },
  };
  const mantineTheme: Parameters<typeof mergeMantineTheme>[1] = {
    ...baseTheme,
    primaryColor: 'black',
    primaryShade: 3,
  };
  return mantineTheme;
};
