import { resolveIntl } from '@shared/helpers';
import { GraphQLClient } from 'graphql-request';

import { env } from './env';
import { getSdk } from './gql/sdk';

const { country } = resolveIntl();

const client = new GraphQLClient(env.gqlUrl, {
  headers: () => ({
    'X-EMBLA-SOURCE': 'WEB',
    'x-embla-country-iso3166': country,
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  }),
});

export const isAuthenticated = () => !!sessionStorage.getItem('token');

const api = getSdk(client);

export default api;
